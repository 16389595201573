import React, {useState} from "react";
import data from "../../data";

import Layout from "../components/Layout";
import SEO from "../components/Seo";

import Header from "../components/Header";
import Loadable from "@loadable/component";

const Footer = Loadable(() => import("../components/Footer/index.js"));
const components = {};
data.sections
        .forEach((v) => {
          components[v.component] = Loadable(() => import(`../components/${v.component}`));
        });

const IndexPage = () => {
 const [visibleComponents, setVisibleComponents] = useState(false);

  return (
    <Layout>
      <SEO />
      <Header
        setVisible={(c) => {
          
          c&&
            setVisibleComponents(true);
        }
        }
      />
      
      {data.sections
        .map((v,idx) => {
          const Comp = components[v.component];
          let isVisible = visibleComponents || idx < 3;
          return (
            v.component && (isVisible ?<Comp name={v.name} content={v.content} />:
              <div
                id={ v.name}
                style={{
                  minHeight: isVisible ? 0 : "100vh",
                  visibility: "hidden",
                  contentVisibility:"hidden"
                }}
              >
              </div>
            )
          );
        })
        .filter((e) => e)}
      
      <Footer />
    </Layout>
  );
};
export default IndexPage;
